var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('ImagePicker', {
    ref: "imagePicker2",
    attrs: {
      "index": _vm.option.componentOptionId,
      "value": _vm.img
    },
    on: {
      "updateImg": function updateImg(v) {
        return _vm.updateOption('img', v);
      }
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v("이미지 설명(alt)")]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "이미지 설명을 입력해주세요.",
      "value": _vm.alt
    },
    on: {
      "input": function input(v) {
        return _vm.updateOption('alt', v);
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }